@import "gerillass";
@import "../scss/variables.scss";

.boardMembersWrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .boardMembers {
    padding: 15vw 0 0 0;

    display: flex;
    align-items: center;
    justify-content: space-around;

    @include breakpoint(max, $tablet) {
      padding: 30vw 0 15vw 0;

      flex-direction: column;
    }

    @include breakpoint(max, $mobile) {
      padding: 40vw 0 15vw 0;

      flex-direction: column;
    }

    .textBox {
      margin-bottom: 20vw;
      margin-right: 15vw;

      @include breakpoint(max, $tablet) {
        margin-bottom: 15vw;
        margin-right: auto;
      }

      .title {
        color: #003566;
        font-size: 4.3vw;
        font-weight: 700;
        line-height: 120%;
        margin-right: 10vw;
        margin-bottom: 3vw;

        @include breakpoint(max, $tablet) {
          font-size: 7vw;
          margin-right: 0;
          padding-bottom: 1.8rem;
        }

        @include breakpoint(max, $mobile) {
          font-size: 10vw;
          font-weight: 700;
          text-align: center;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      .text {
        color: #003566;
        display: block;
        font-size: 1.2vw;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 1rem;
        padding-top: 1rem;
        position: relative;
        width: 33vw;

        // &::first-letter {
        //   margin-left: 10vw;

        //   @include breakpoint(max, $tablet) {
        //     margin-left: 0;
        //   }
        // }

        @include breakpoint(max, $tablet) {
          font-size: 2.5vw;
          font-weight: 300;
          width: 60vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 1rem;
          font-weight: 300;
          text-align: center;
          width: 90vw;
        }
      }
    }

    .memberList {
      flex: 1;

      .member {
        cursor: pointer;
        margin-bottom: 2rem;
        padding-bottom: 1.75rem;
        position: relative;
        width: 35vw;

        * {
          cursor: pointer;
        }

        &.inProgress {
          pointer-events: none;
        }

        @include breakpoint(max, $tablet) {
          width: 80vw;
        }

        @include breakpoint(max, $mobile) {
          width: 90vw;
        }

        &::after {
          content: "";
          @include position(absolute, auto 0 0 0);
          @include sizer(100%, 1px);
          background: #003566;
        }

        .title {
          color: #003566;
          font-size: 1.5vw;
          font-weight: 700;
          line-height: 120%;
          margin-bottom: 0.5rem;
          transition: 0.2s all ease-in-out;

          @include breakpoint(max, $tablet) {
            font-size: 3vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 5vw;
          }
        }

        .role {
          color: #003566;
          display: block;
          font-size: 14px;
          font-weight: 300;
          line-height: 120%;
          transition: 0.25s all ease-in-out;

          @include breakpoint(max, $tablet) {
            font-size: 2vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 3vw;
          }
        }

        @include breakpoint(min, $tablet) {
          &:hover {
            .title,
            .role {
              transform: translateX(-1rem);
            }
          }
        }

        .arrow {
          @include position(absolute, auto 0 1rem auto);
          @include sizer(1rem);

          &.inProgress {
            opacity: 0.3;
          }
        }
      }
    }

    .sphere {
      @include position(absolute, auto auto -20% -5%);
      background: transparent;
      filter: invert(1);
      opacity: 0.5;
      transform: rotate(135deg);
      width: 25vw;

      @include breakpoint(max, $tablet) {
        display: none;
      }
    }
  }
}
