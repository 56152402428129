@import "gerillass";
@import "../scss/variables.scss";

.contactWrapper {
  padding: 14vw 6vw 5vw 6vw;

  display: flex;
  align-items: center;

  @include breakpoint(max, $tablet) {
    padding: 25vw 5vw 10vw 5vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 40vw 5vw 20vw 5vw;
  }

  .contact {
    .title {
      color: #003566;
      font-size: 4vw;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -3.5px;
      margin-bottom: 6vw;

      @include breakpoint(max, $tablet) {
        font-size: 7vw;
        margin-bottom: 15vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 10vw;
        letter-spacing: -1.5px;
        margin-bottom: 30vw;
      }
    }

    .bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;

      @include breakpoint(max, $tablet) {
        flex-direction: column;
      }

      .successWrapper {
        position: relative;

        .formWrapper {
          opacity: 1;
          visibility: visible;
          transition: 0.4s all ease;

          &.success {
            opacity: 0;
            visibility: hidden;

            @include breakpoint(max, $mobile) {
              max-height: 10vw;
            }
          }

          .row {
            display: flex;
            flex-wrap: wrap;

            @include breakpoint(max, $mobile) {
              margin-bottom: 10vw;
            }

            .submitBtn {
              @include position(absolute, auto 0 0.75rem auto);
              border: none;
              background: transparent;
              cursor: pointer;
              opacity: 0.3;
              pointer-events: none;
              transition: 0.4s all ease-in-out;

              display: flex;
              align-items: center;
              justify-content: center;

              @include breakpoint(max, $mobile) {
                display: none;
              }

              &.mobile {
                display: none;

                @include breakpoint(max, $mobile) {
                  display: flex;
                  position: relative;
                  margin: 15vw auto 0 0;
                }
              }

              * {
                cursor: pointer;
              }

              &.enabled {
                opacity: 1;
                pointer-events: auto;
              }

              .text {
                color: #003566;
                font-family: "Maison-Neue-Ext";
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 100%;
              }

              .arrow {
                @include sizer(1rem);
                margin-left: 1rem;
              }
            }

            .inputWrapper {
              @include sizer(18vw, 2.75rem);
              margin-right: 5vw;
              margin-bottom: 5vw;
              position: relative;

              @include breakpoint(max, $tablet) {
                @include sizer(40vw, 3rem);
                margin-bottom: 7vw;
              }

              @include breakpoint(max, $mobile) {
                @include sizer(90vw, 3rem);
                margin-bottom: 10vw;
                margin-right: 0;
              }

              &.message {
                flex: 1;
              }

              &:last-child {
                margin-right: 0;
                margin-bottom: 1.5vw;
              }

              .formLabel {
                @include position(absolute, -100% 0 0 0);
                color: #003566;
                display: block;
                font-size: 1.5vw;
                font-weight: 700;
                line-height: 100%;
                margin-bottom: 1rem;
                padding-top: 1rem;
                text-align: left;

                @include breakpoint(max, $tablet) {
                  font-size: 2.5vw;
                }

                @include breakpoint(max, $mobile) {
                  @include position(absolute, -50% 0 0 0);
                  font-size: 4.5vw;
                }
              }

              .formInput {
                @include position(absolute, 0 0 0 0);
                @include sizer(100%);
                border: none;
                background: none;
                border-bottom: 1px solid #00356618;
                outline: none;
                transition: 0.3s all ease;

                color: #003566;
                font-size: 1.25rem;
                font-family: "Maison-Neue-Ext";
                font-weight: 500;
                line-height: 100%;
                margin: 0;
                padding: 0;

                &.alert {
                  background: #0084ff12;
                }

                @include breakpoint(max, $tablet) {
                  font-size: 2vw;
                }

                @include breakpoint(max, $mobile) {
                  @include position(absolute, 10% 0 0 0);
                  font-size: 4vw;
                }

                &.textarea {
                  width: 77%;
                  line-height: 160%;
                  font-size: 1rem;

                  @include breakpoint(max, $mobile) {
                    width: 100%;
                  }
                }
              }
            }

            .confirmation {
              cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: flex-start;

              @include breakpoint(max, $mobile) {
                align-items: flex-start;
              }

              * {
                cursor: pointer;
              }

              &.enabled {
                .checkbox {
                  .check {
                    opacity: 1;
                  }
                }

                .checkText {
                  color: #003566;
                }
              }

              .checkbox {
                @include sizer(1.5rem);
                border: 1px solid #929fac;
                border-radius: 50%;
                position: relative;

                flex-shrink: 0;

                @include breakpoint(max, $mobile) {
                  @include sizer(1rem);
                }

                .check {
                  @include sizer(70%);
                  @include center;
                  background: #003566;
                  border-radius: 50%;
                  opacity: 0.3;
                  position: absolute;
                  transition: 0.2s all ease-in-out;
                }
              }

              .checkText {
                color: #929fac;
                font-family: "Maison-Neue-Ext";
                font-size: 1rem;
                font-weight: 500;
                line-height: 140%;
                margin-left: 1rem;
                transition: 0.4s all ease-in-out;

                @include breakpoint(max, $mobile) {
                  font-size: 0.7rem;
                  margin-left: 0.5rem;
                }
              }
            }
          }
        }

        .successMessage {
          position: absolute;
          @include center;
          opacity: 0;
          visibility: hidden;
          transition: 0.4s all ease;
          transition-delay: 0.4s;

          .successText {
            color: #003566;
            font-size: 2rem;
            font-weight: 700;
            line-height: 100%;
            white-space: nowrap;

            @include breakpoint(max, $mobile) {
              font-size: 1rem;
            }
          }

          &.success {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .contactInfo {
        margin-top: -3vw;
        margin-left: 20vw;
        width: 20vw;

        @include breakpoint(max, $tablet) {
          margin-left: 0;
          margin-right: auto;
          margin-top: 20vw;
          width: 40vw;
        }

        @include breakpoint(max, $mobile) {
          margin-top: 40vw;
          width: 60vw;
        }

        .companyName {
          border-bottom: 1px solid rgba(128, 128, 128, 0.453);
          color: #003566;
          font-size: 1.25vw;
          font-weight: 900;
          letter-spacing: -1px;
          line-height: 160%;
          margin-bottom: 2rem;
          padding-bottom: 1rem;

          @include breakpoint(max, $tablet) {
            font-size: 1.25rem;
          }

          @include breakpoint(max, $mobile) {
            font-size: 1rem;
            letter-spacing: 0;
          }
        }

        .contactInfoRow {
          margin-bottom: 2vw;

          display: flex;
          align-items: flex-start;

          &:nth-child(3) {
            margin-bottom: 0;
          }

          .label {
            color: #003566;
            font-size: 1.2rem;
            font-weight: 900;
            line-height: 100%;

            flex-basis: 30%;
            flex-shrink: 0;

            @include breakpoint(max, $mobile) {
              font-size: 13px;
              flex-basis: 25%;
            }
          }

          .info {
            color: #003566;
            font-size: 1rem;
            font-weight: 300;
            line-height: 140%;
            text-align: left;
            text-decoration: none;

            flex: 1;

            &.email {
              display: block;
              transition: 0.2s all ease-in-out;

              &:hover {
                transform: translateX(1rem);
              }
            }

            @include breakpoint(max, $mobile) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
