@import "gerillass";
@import "../scss/variables.scss";

.executivePanel {
  @include position(fixed, 0 0 0 0);
  @include sizer(100%);
  background: #003566;

  display: flex;
  align-items: center;
  justify-content: space-around;

  z-index: 9999;
  will-change: transform;

  @include breakpoint(max, $tablet) {
    flex-direction: column;
  }

  .imgWrapper {
    @include sizer(25vw, 35vw);
    overflow: hidden;
    will-change: transform;

    @include breakpoint(max, $tablet) {
      @include sizer(20vw, 25vw);
      @include position(absolute, 12vw auto auto 5vw);
    }

    @include breakpoint(max, $tabletMini) {
      display: none;
    }

    .img {
      @include sizer(100%);
      display: block;
      object-fit: cover;
      will-change: transform;
    }
  }

  .details {
    @include sizer(40vw, 72vh);

    @include breakpoint(max, $tablet) {
      @include sizer(auto);
      margin-top: 55vw;
    }

    @include breakpoint(max, $tabletMini) {
      margin-top: 0vw;
    }

    .titleWrapper {
      position: relative;
      margin-bottom: 4rem;

      @include breakpoint(max, $mobile) {
        margin-bottom: 2rem;
      }

      .title {
        color: #ffffff;
        font-family: "Maison-Neue-Ext";
        font-size: 2.1vw;
        font-weight: 700;
        line-height: 120%;
        padding-bottom: 0.25rem;
        will-change: transform;

        @include breakpoint(max, $tablet) {
          font-size: 4vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 7vw;
        }
      }

      .role {
        color: #ffffff;
        display: block;
        font-family: "Maison-Neue-Ext";
        font-size: 1rem;
        font-weight: 500;
        line-height: 120%;
        padding-right: 1rem;

        @include breakpoint(max, $mobile) {
          font-size: 0.75rem;
        }
      }

      .arrow {
        @include position(absolute, auto 1rem 1rem auto);
        @include sizer(1rem);

        .icon {
          @include sizer(100%);
        }
      }
    }

    .scrollWrapper {
      position: relative;

      .progress {
        @include position(absolute, 0 0 0 -10%);
        @include sizer(2px, 100%);
        background: gray;
        display: block;
        will-change: transform;

        @include breakpoint(max, $tablet) {
          display: none;
        }

        @include breakpoint(max, $mobile) {
          @include position(absolute, 0 auto 0 -5%);
          @include sizer(2px, 100%);
          display: block;
        }

        .bar {
          @include position(absolute, 0 0 0 0);
          @include sizer(100%);
          background: #ffffff;
        }
      }

      .text {
        @include sizer(35vw, 54vh);
        overflow: scroll;
        will-change: transform;

        @include breakpoint(max, $tablet) {
          @include sizer(90vw, 70vh);
        }

        @include breakpoint(max, $mobile) {
          @include sizer(85vw, 65vh);
        }

        &::-webkit-scrollbar {
          display: none;
        }

        &::after {
          content: "";
          @include position(absolute, auto 0 0 0);
          @include sizer(100%, 30%);
        }

        .par {
          color: #ffffff;
          font-family: "Maison-Neue-Ext";
          font-size: 1rem;
          font-weight: 500;
          line-height: 200%;
          padding-bottom: 2rem;

          @include breakpoint(max, $tablet) {
            font-size: 18px;
          }

          @include breakpoint(max, $tabletMini) {
            font-size: 1rem;
          }

          @include breakpoint(max, $mobile) {
            font-size: 0.75rem;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .close {
    @include position(absolute, 4vw 5vw auto auto);
    @include sizer(3.5rem);
    border: 1px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    will-change: transform;

    @include breakpoint(max, $mobile) {
      @include position(absolute, 5vw 5vw auto auto);
      @include sizer(2rem);
    }

    .icon {
      @include sizer(30%);
      position: absolute;
      @include center;
    }
  }
}
