@import "gerillass";
@import "../scss/variables.scss";

.executivesWrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .executives {
    padding: 5vw 0;
    width: 77vw;

    display: flex;
    align-items: center;

    @include breakpoint(max, $tablet) {
      padding: 30vw 0 10vw 0;

      flex-direction: column;
      align-items: flex-start;
    }

    @include breakpoint(max, $mobile) {
      padding: 40vw 0 20vw 0;
      align-items: center;
    }

    .textBox {
      margin-right: 10vw;
      margin-left: -3vw;

      @include breakpoint(max, $tablet) {
        margin-right: auto;
      }

      @include breakpoint(max, $mobile) {
        margin-right: 0;
        margin-left: 0;
      }

      .title {
        color: #003566;
        font-size: 4.3vw;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 3vw;

        @include breakpoint(max, $tablet) {
          font-size: 7vw;
          line-height: 100%;
          padding-bottom: 1.8rem;
        }

        @include breakpoint(max, $mobile) {
          font-size: 11vw;
          font-weight: 700;
          text-align: center;
          margin-right: 0;
        }

        //   br {
        //     display: none;

        //     @include breakpoint(max, $tablet) {
        //       display: block;
        //     }
        //   }
      }

      .text {
        color: #003566;
        display: block;
        font-size: 1.2vw;
        font-weight: 500;
        line-height: 200%;
        margin-bottom: 1rem;
        padding-top: 1rem;
        position: relative;
        width: 35vw;

        @include breakpoint(max, $tablet) {
          font-size: 2.5vw;
          font-weight: 300;
          width: 75vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 1rem;
          font-weight: 300;
          text-align: center;
          width: 90vw;
        }

        // &::first-letter {
        //   margin-left: 10vw;

        //   @include breakpoint(max, $tablet) {
        //     margin-left: 0;
        //   }
        // }
      }
    }

    .cards {
      margin-top: 10vw;

      display: flex;

      @include breakpoint(max, $mobile) {
        flex-wrap: wrap;
        flex-direction: column;
      }

      .executiveCard {
        @include sizer(20vw, 30vw);
        border: 1px solid #003566;
        cursor: pointer;
        margin-right: 3vw;

        display: flex;
        flex-direction: column;

        &:last-child {
          margin-right: 0;
        }

        @include breakpoint(max, $tablet) {
          @include sizer(33vw, auto);
          margin-right: 10vw;
        }

        @include breakpoint(max, $mobile) {
          @include sizer(80vw, 70vh);
          margin-right: 0;
          margin-bottom: 10vw;
        }

        .imgWrapper {
          flex: 1;
          overflow: hidden;

          @include breakpoint(min, $tablet) {
            &:hover {
              .img {
                filter: grayscale(0);
                transform: scale(1.05);
              }
            }
          }

          .img {
            @include sizer(100%);
            display: block;
            object-fit: cover;
            object-position: 50% 0%;

            filter: grayscale(100);
            transform: scale(1);
            transition: 0.3s all ease-in-out;
          }
        }

        .info {
          flex-basis: 8%;
          padding: 0.75rem 0.75rem;
          position: relative;

          .title {
            color: #003566;
            font-family: "Maison-Neue-Ext";
            font-size: 1.45vw;
            font-weight: 700;
            line-height: 120%;
            padding-right: 1rem;

            @include breakpoint(max, $tablet) {
              font-size: 2.5vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 5vw;
            }
          }

          .role {
            color: #003566;
            font-family: "Maison-Neue-Ext";
            font-size: 11px;
            font-weight: 500;
            line-height: 120%;
            padding-right: 1rem;

            @include breakpoint(max, $tablet) {
              font-size: 1.5vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 12px;
            }
          }

          .arrow {
            @include position(absolute, auto 0.75rem 0.75rem auto);
            @include sizer(0.75rem);

            .icon {
              @include sizer(100%);
            }
          }
        }
      }
    }
  }
}
