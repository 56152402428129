@import "gerillass";
@import "../scss/variables.scss";

.modal {
  @include position(fixed, 0 0 0 0);
  @include sizer(100%);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background: rgba(0, 0, 0, 0.7);
  overflow: scroll;

  z-index: 10000;

  .close {
    @include sizer(1.5vw);
    position: sticky;
    top: 5vw;
    left: 90vw;
    border: 1px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.7;
    padding: 1rem;

    @include breakpoint(max, $tablet) {
      @include sizer(3vw);
    }

    @include breakpoint(max, $mobile) {
      left: 83vw;
    }
  }
}
