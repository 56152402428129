@use "./assets/fonts/fonts.scss";
@import "gerillass";
@include reset-css;

body {
  font-family: "Maison-Neue-Ext";
  background: #fdfdfd;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
a {
  @include antialias;
  cursor: default;
}

// * {
//   outline: 1px solid red;
// }
