@import "gerillass";
@import "../scss/variables.scss";

.accordionItems {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding-left: 2vw;
  transition: 400ms all ease-in-out;

  @include breakpoint(max, $mobile) {
    padding-left: 0;
  }

  .inner {
    padding-top: 1vw;
    padding-bottom: 2vw;

    @include breakpoint(max, $mobile) {
      padding-top: 4vw;
      padding-bottom: 12vw;
    }
  }

  &.active {
    max-height: 400px;
    opacity: 1;
  }
}

.homeWrapper {
  .hero {
    background: #000000;
    position: relative;
    overflow: hidden;
    width: 100%;

    z-index: 100;

    &::after {
      content: "";
      @include position(absolute, null 0 0 0);
      @include sizer(100vw, 50%);
      background: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));

      z-index: 0;

      @include breakpoint(max, $mobile) {
        @include sizer(100vw, 55%);
      }
    }

    .imgWrapper {
      @include sizer(100vw, 110vh);
      overflow: hidden;

      @include breakpoint(max, $mobile) {
        height: 100vh;
      }

      .heroImg {
        @include sizer(100%);
        display: block;
        object-fit: cover;

        @include breakpoint(max, $mobile) {
          height: 100vh;
        }
      }
    }

    .title {
      @include position(absolute, auto auto 10vw 5vw);
      color: #ffffff;
      font-size: 3vw;
      font-weight: 500;
      line-height: 125%;

      z-index: 50;

      @include breakpoint(max, $tablet) {
        @include position(absolute, auto 0 27vw 0);
        font-size: 7vw;
        line-height: 110%;
        text-align: center;
        width: 100vw;
      }

      @include breakpoint(max, $mobile) {
        @include position(absolute, auto 0 25vw 0);
        font-size: 10vw;
      }

      br {
        display: inline;

        &.tablet {
          display: none;

          @include breakpoint(max, $tablet) {
            display: inline;
          }
        }

        &.mobile {
          display: none;

          @include breakpoint(max, $mobile) {
            display: inline;
          }
        }
      }

      strong {
        font-weight: 700;
        font-style: italic;
      }
    }

    .heroText {
      z-index: 100;

      @include position(absolute, auto 10vw 10vw auto);
      width: 15vw;

      @include breakpoint(max, $tablet) {
        display: none;
      }

      &::before {
        content: "";
        @include position(absolute, 0 0 0 0);
        background: #000000 0% 0% no-repeat padding-box;
        filter: blur(50px);
        opacity: 0.54;

        z-index: -1;
      }

      &::after {
        content: "";
        @include position(absolute, 7% null 10% -10%);
        @include sizer(2px, 80%);
        background: #ffffff;
        opacity: 0.6;
      }

      p {
        color: #ffffff;
        font-size: 0.85vw;
        font-weight: 500;
        line-height: 210%;
        position: relative;

        z-index: 200;

        &:not(:last-child) {
          margin-bottom: 1.5vw;
        }

        @include breakpoint(max, $tablet) {
          font-size: 12px;
        }
      }
    }

    .scalingWrapper {
      @include position(absolute, auto 0 -15px 0);
      z-index: 100;

      .scaling {
        width: 100vw;
        height: auto;
        //background: red;
        display: block;

        will-change: transform;

        // &.animate {
        //   path {
        //     transition: all 0.8s ease-in-out;
        //   }

        //   path:hover {
        //     d: path("M0 20 Q 50 0 100 20 L100 60 L0 60 Z");
        //   }
        // }
      }
    }
  }

  .aboutUs {
    background: #fdfdfd;
    overflow: hidden;
    position: relative;

    z-index: 200;

    background-attachment: fixed;
    background-image: linear-gradient(to bottom, #fdfdfd, rgba(255, 255, 255, 0)), url(../img/sticky-bg.svg);
    background-position: 0 100%;

    @include breakpoint(max, $tablet) {
      background-image: none;
    }

    .heroTextTouchScreen {
      background: #fdfdfd;
      background-image: linear-gradient(rgba(255, 255, 255, 0.01) 10%, #fdfdfd),
        url(../img/hero-text-touchscreen-bg.png);
      background-position: 0 -50%, 0 -300%;
      background-blend-mode: normal, exclusion;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 7vw 0;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      display: none;

      z-index: 200;

      @include breakpoint(max, $tablet) {
        display: flex;
      }

      @include breakpoint(max, $mobile) {
        padding: 7vw 0 28vw 0;
        margin-top: 15vw;
      }

      .textWrapper {
        p {
          color: #414141;
          font-size: 2.5vw;
          font-weight: 500;
          line-height: 200%;
          position: relative;
          text-align: center;
          width: 65vw;

          z-index: 20;

          &:not(:last-child) {
            margin-bottom: 1.5vw;

            @include breakpoint(max, $mobile) {
              margin-bottom: 8vw;
            }
          }

          @include breakpoint(max, $mobile) {
            font-size: 5.5vw;
            font-weight: 300;
            line-height: 170%;
            width: 80vw;
          }
        }
      }
    }

    .desktop {
      padding: 3vw 7vw 3vw 7vw;
    }

    .title {
      color: #727272;
      display: block;
      font-size: 4vw;
      font-weight: 700;
      line-height: 180%;
      position: relative;

      @include breakpoint(max, $tablet) {
        font-size: 7vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 11vw;
        text-align: center;
        padding-bottom: 1rem;
      }

      &::after {
        content: "";
        @include position(absolute, null 0 0 0);
        @include sizer(65%, 1px);
        background: #727272;
        opacity: 0.5;

        @include breakpoint(max, $mobile) {
          display: none;
        }
      }
    }

    .oWrapper {
      overflow: hidden;
    }

    .texts {
      padding: 5vw 5vw 0 5vw;

      display: flex;
      justify-content: flex-start;

      @include breakpoint(max, $mobile) {
        justify-content: center;
      }

      &.right {
        padding: 0 5vw;
        justify-content: flex-end;

        @include breakpoint(max, $tablet) {
          padding: 7vw 0;
        }

        @include breakpoint(max, $mobile) {
          padding: 7vw 0;
          justify-content: center;
        }
      }

      .textBox {
        h3 {
          color: #003566;
          font-size: 2vw;
          font-weight: 700;
          line-height: 140%;

          @include breakpoint(max, $tablet) {
            font-size: 3.5vw;
            line-height: 130%;
            padding-bottom: 1.8rem;
          }

          @include breakpoint(max, $mobile) {
            font-size: 6.5vw;
            font-weight: 700;
            text-align: center;
          }

          br {
            display: none;

            @include breakpoint(max, $tablet) {
              display: block;
            }
          }
        }

        .text {
          color: #003566;
          display: block;
          font-size: 1rem;
          font-weight: 500;
          line-height: 200%;
          margin-bottom: 1rem;
          padding-top: 1rem;
          position: relative;
          width: 30vw;

          @include breakpoint(max, $tablet) {
            font-size: 2vw;
            font-weight: 300;
            width: 50vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 1rem;
            font-weight: 300;
            text-align: center;
            width: 75vw;
          }

          &.lined {
            &::before {
              content: "";
              @include position(absolute, -10% auto 0 -5%);
              @include sizer(1px, 80%);
              background: #ea0029;
              opacity: 0.4;

              @include breakpoint(max, $mobile) {
                @include position(absolute, -5% 50% auto 0);
                @include center(horizontal);
                @include sizer(35%, 1px);
              }
            }
          }
        }

        .btnWrapper {
          margin-top: 2rem;
          text-decoration: none;

          @include breakpoint(max, $mobile) {
            margin-top: 0;
          }

          a {
            text-decoration: none !important;
          }

          .expandBtn {
            background: none;
            border: none;
            border-bottom: 1px solid #727272;
            opacity: 0.6;

            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint(max, $mobile) {
              margin-left: auto;
              margin-right: auto;
            }

            .btnText {
              color: #727272;
              font-family: "Maison-Neue-Ext";
              font-size: 1rem;
              font-weight: 700;
              font-style: italic;
              line-height: 200%;
              padding-right: 1rem;
            }
          }
        }
      }
    }

    .sphere {
      @include position(absolute, 10% -5% auto auto);
      filter: invert(1);
      width: 32vw;

      @include breakpoint(max, $tablet) {
        @include position(absolute, 38% -30% auto auto);
        width: 59vw;
      }

      @include breakpoint(max, $mobile) {
        display: none;
      }
    }
  }

  .ourFunds {
    background: #fdfdfd;
    position: relative;

    z-index: 200;

    background-attachment: fixed;
    background-image: url(../img/sticky-bg.svg);
    background-position: 0 0;

    @include breakpoint(max, $tablet) {
      background-image: none;
      overflow: hidden;
      padding-bottom: 10vw;
    }

    .video {
      overflow: hidden;
      position: relative;

      z-index: 200;

      // &:after {
      //   content: "";
      //   @include position(absolute, 0 0 0 0);
      //   @include sizer(100%);
      //   background: #0058ff;

      //   mix-blend-mode: hard-light;
      //   opacity: 0.3;
      // }

      .mediaWrapper {
        z-index: 10;
        flex: 1;
        overflow: hidden;

        .inner {
          position: relative;
          padding-top: 56.25%;
          overflow: hidden;
          transform: scale(1.005);
        }
      }

      .kurucesme {
        width: 100%;
      }

      .title {
        color: #ffffff;
        font-size: 11.25vw;
        font-weight: 700;
        line-height: 100%;
        @include position(absolute, auto auto 0 5vw);

        @include breakpoint(max, $tablet) {
          display: none;
        }
      }
    }

    .titleTouchScreen {
      color: #727272;
      display: block;
      font-size: 4vw;
      font-weight: 500;
      line-height: 180%;
      position: relative;

      display: none;

      @include breakpoint(max, $tablet) {
        display: block;
        font-size: 7vw;
        margin: 12vw 0 0 5vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 11vw;
        padding-bottom: 1rem;
        text-align: center;
        margin-left: 0;
      }

      &::after {
        content: "";
        @include position(absolute, null 0 0 0);
        @include sizer(65%, 1px);
        background: #727272;
        opacity: 0.5;

        @include breakpoint(max, $mobile) {
          display: none;
        }
      }
    }

    .textWrapper {
      padding: 10vw 0 13vw 0;

      display: flex;
      align-items: flex-start;
      justify-content: center;

      @include breakpoint(max, $tablet) {
        padding: 12vw;

        flex-direction: column;
        align-items: flex-start;
      }

      @include breakpoint(max, $mobile) {
        padding: 12vw 0;

        align-items: center;
        justify-content: center;
      }

      .textBox {
        &:nth-child(1) {
          margin-right: 10vw;

          @include breakpoint(max, $tablet) {
            margin-right: 0;
            margin-bottom: 20vw;
          }
        }

        h3 {
          color: #003566;
          font-size: 2vw;
          font-weight: 700;
          line-height: 140%;
          padding-bottom: 1rem;

          @include breakpoint(max, $tablet) {
            font-size: 3.5vw;
            line-height: 130%;
            padding-bottom: 1.8rem;
          }

          @include breakpoint(max, $mobile) {
            font-size: 6.5vw;
            font-weight: 700;
            text-align: center;
          }
        }

        .text {
          color: #003566;
          display: block;
          font-size: 1rem;
          font-weight: 500;
          line-height: 180%;
          margin-bottom: 1rem;
          position: relative;
          width: 18vw;

          @include breakpoint(max, $tablet) {
            font-size: 2vw;
            font-weight: 300;
            width: 50vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 1rem;
            font-weight: 300;
            text-align: center;
            width: 80vw;
          }

          &.lined {
            &::before {
              content: "";
              @include position(absolute, null null 10% -5%);
              @include sizer(1px, 130%);
              background: #ea0029;
              opacity: 0.4;

              @include breakpoint(max, $tablet) {
                @include position(absolute, -40% null null -5%);
                @include sizer(2px, 200%);
              }

              @include breakpoint(max, $mobile) {
                @include position(absolute, -25% 50% null 0);
                @include center(horizontal);
                @include sizer(35%, 1px);
              }
            }
          }
        }
      }
    }

    .sphere {
      @include position(absolute, auto auto -10% -5%);
      background: transparent;
      filter: invert(1);
      opacity: 0.5;
      transform: rotate(135deg);
      width: 25vw;

      z-index: -1;

      @include breakpoint(max, $tablet) {
        @include position(absolute, null -10% -7% auto);
        width: 41vw;
      }

      @include breakpoint(max, $mobile) {
        @include position(absolute, 10% -23% auto auto);
        transform: rotate(90deg);
        width: 77vw;
      }
    }
  }
}
