@import "gerillass";
@import "../scss/variables.scss";

.selectWrapper {
  @include sizer(30px, 100%);
  margin: 0.5rem 0.75rem;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(max, $tablet) {
    @include sizer(35px, 100%);
  }

  @include breakpoint(max, $mobile) {
    display: none;
  }

  &.lg {
    @include sizer(50px, 100%);
    opacity: 0.7;

    @include breakpoint(max, $mobile) {
      display: flex;
      @include sizer(30px, 100%);
    }

    .langSelect {
      font-size: 2vw;
      font-weight: 300;

      @include breakpoint(max, $mobile) {
        font-size: 3vw;
      }
    }

    .arrow {
      @include sizer(12px);

      @include breakpoint(max, $mobile) {
        @include sizer(8px);
      }
    }
  }

  .langSelect {
    @include sizer(100%);
    background: transparent;
    border: none;
    color: #ffffff;
    font-family: "Maison-Neue-Ext";
    font-size: 12px;
    font-weight: 300;
    outline: none;
    // width: 30px;

    -webkit-appearance: none;
    -moz-appearance: none;

    @include breakpoint(max, $tablet) {
      font-size: 14px;
    }

    @include breakpoint(max, $tabletMini) {
      font-size: 12px;
    }

    @include breakpoint(max, $mobile) {
      font-size: 10px;
    }
  }

  .arrow {
    @include position(absolute, 50% 0 0 auto);
    @include sizer(9px);
    margin-left: 0.25rem;
    pointer-events: none;
    transform: rotate(90deg) translateX(-50%);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
