@import "gerillass";
@import "../scss/variables.scss";

.content {
  margin: 10vw 20vw 10vw 7vw;
  position: relative;

  @include breakpoint(max, $tablet) {
    margin: 10vw 5vw;
  }

  @include breakpoint(max, $mobile) {
    margin: 20vw 5vw;
  }

  .latinList,
  .bulletList {
    color: #ffffff;
    padding-left: 2rem;
  }

  .latinList {
    list-style: lower-latin;
  }

  .bulletList {
    list-style: disc;
  }

  .titleL {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: 3rem;
    text-align: left;

    @include breakpoint(max, $tablet) {
      font-size: 1.5rem;
    }

    @include breakpoint(max, $mobile) {
      font-size: 1.2rem;
      text-align: center;
    }
  }

  .titleM {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: 1.5rem;
    padding-top: 5rem;

    @include breakpoint(max, $tablet) {
      font-size: 1.25rem;
      text-align: left;
    }

    @include breakpoint(max, $mobile) {
      font-size: 1rem;
      padding-top: 3rem;
    }

    strong {
      font-weight: 700;
    }
  }

  .titleS {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: 1.5rem;
    padding-top: 2rem;

    strong {
      font-weight: 700;
    }
  }

  .text {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 300;
    line-height: 200%;
    padding-bottom: 1rem;

    @include breakpoint(max, $tablet) {
      font-size: 1rem;
    }

    @include breakpoint(max, $mobile) {
      font-size: 12px;
    }

    strong {
      font-weight: 700;
    }
  }
}
