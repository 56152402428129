@font-face {
  font-family: "Maison-Neue-Ext";
  src: url("./MaisonNeueExt/MaisonNeueExt-Bold.woff2") format("woff2"),
    url("./MaisonNeueExt/MaisonNeueExt-Bold.woff2") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Maison-Neue-Ext";
  src: url("./MaisonNeueExt/MaisonNeueExt-Medium.woff2") format("woff2"),
    url("./MaisonNeueExt/MaisonNeueExt-Medium.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Maison-Neue-Ext";
  src: url("./MaisonNeueExt/MaisonNeueExt-Book.woff2") format("woff2"),
    url("./MaisonNeueExt/MaisonNeueExt-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Maison-Neue-Ext";
  src: url("./MaisonNeueExt/MaisonNeueExt-Light.woff2") format("woff2"),
    url("./MaisonNeueExt/MaisonNeueExt-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
