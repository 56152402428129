@import "gerillass";
@import "../scss/variables.scss";

$headerHeight: 117px;
$headerHeightTablet: 110px;
$headerHeightMobile: 90px;

.headerMobile {
  @include position(absolute, 0 0 auto 0);
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  z-index: 5000;

  @include breakpoint(max, $mobile) {
    padding: 1rem;
  }

  .imgWrapper {
    z-index: 400;

    .wamLogo {
      @include breakpoint(max, $mobile) {
        width: 45vw;
      }
    }
  }

  .hamburger {
    @include sizer(5vw);
    border-radius: 50%;
    border: 1px solid #ffffff;
    padding: 0.5rem;
    position: relative;
    z-index: 400;

    &.dark {
      border: 1px solid #727272;
    }

    @include breakpoint(max, $mobile) {
      @include sizer(6vw);
    }

    .hamburgerIcon {
      @include center;
      @include sizer(2vw);
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;

      @include breakpoint(max, $mobile) {
        @include sizer(5vw);
      }
    }

    .dWrapper {
      @include sizer(5vw);
      @include position(absolute, 0% auto auto -140%);

      @include breakpoint(max, $mobile) {
        @include position(absolute, 5% auto auto -130%);
      }
    }
  }

  .hamburgerMenu {
    @include position(absolute, 0 0 0 0);
    @include sizer(100vw, 100vh);
    backdrop-filter: blur(16px);
    background: rgba(0, 0, 0, 0.15);
    padding-top: $headerHeightMobile;
    z-index: 200;

    &.dark {
      background: #ffffff;
    }

    .link {
      padding: 2.5vw 0;
      text-decoration: none;
      position: relative;

      display: flex;
      align-items: center;
      flex: 1;

      @include breakpoint(max, $mobile) {
        padding: 4vw 0;
      }

      &.withLine::after {
        content: "";
        @include position(absolute, auto auto 0 auto);
        @include sizer(100%, 1px);
        background: #ffffff;
        opacity: 0.3;
        transition: 0.2s all ease;
      }

      &.withLine.dark::after {
        background: #003566;
      }

      .arrow {
        width: 1.5vw;
        margin-left: 3vw;
        transform: rotate(90deg);
        opacity: 0.8;
      }

      .text {
        color: #ffffff;
        font-family: "Maison-Neue-Ext";
        font-size: 3.5vw;
        font-weight: 300;

        &.dark {
          color: #003566;
        }

        @include breakpoint(max, $mobile) {
          font-size: 5vw;
        }
      }
    }

    .subLink {
      padding: 2.5vw 0;
      text-decoration: none;
      position: relative;

      display: flex;
      align-items: center;
      flex: 1;

      will-change: transform;

      @include breakpoint(max, $mobile) {
        padding: 4vw 0;
      }

      &.withLine::after {
        content: "";
        @include position(absolute, auto auto 0 auto);
        @include sizer(100%, 1px);
        background: #ffffff;
        opacity: 0.3;
        transition: 0.2s all ease;
      }

      &.withLine.dark::after {
        background: #003566;
      }

      .arrow {
        width: 1.25vw;
        margin-right: 2vw;
      }

      .text {
        color: #ffffff;
        font-family: "Maison-Neue-Ext";
        font-size: 2.5vw;
        font-weight: 300;

        &.dark {
          color: #003566;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
        }
      }
    }

    .menuItems {
      margin: 5vw 4vw 0 4vw;

      .menuItemWrapper {
        display: flex;
        flex-direction: column;

        .menuItem {
          display: flex;
        }

        .hamburgerSubmenu {
          margin-left: 5vw;

          .submenuItem {
            display: flex;

            &.inProgress {
              opacity: 0.3;
              pointer-events: none;
            }
          }

          .innerItems {
            display: flex;
            flex-direction: column;

            .submenuItemInner {
              .subLink {
                margin-left: 1rem;

                .text {
                  color: #ffffff;
                  font-family: "Maison-Neue-Ext";
                  font-size: 2vw;
                  font-weight: 300;

                  &.dark {
                    color: #003566;
                  }

                  @include breakpoint(max, $mobile) {
                    font-size: 3vw;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
