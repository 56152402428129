@import "gerillass";
@import "../scss/variables.scss";

$headerHeight: 117px;
$headerHeightTablet: 110px;
$headerHeightMobile: 90px;

.header {
  @include position(absolute, 0 0 auto 0);
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: $headerHeight;
  justify-content: stretch;
  width: 100%;
  z-index: 5000;

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    z-index: 100;
  }

  .nav {
    align-items: stretch;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: space-between;
    z-index: 100;

    &.dark {
      border-top: 1px solid #72727215;
    }

    .imgWrapper {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      padding-left: 2rem;

      display: flex;
      align-items: center;
      flex: 1;

      &.dark {
        border-bottom: 1px solid #72727215;
      }
    }

    .navItems {
      align-items: stretch;
      display: flex;
      position: relative;

      .navItem {
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        border-left: 1px solid rgba(255, 255, 255, 0.15);
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 2.25rem 3rem;
        transition: 0.2s all ease;

        * {
          cursor: pointer;
        }

        &.active {
          border-bottom: 1px solid rgba(255, 255, 255, 0);
        }

        &.dark {
          border-bottom: 1px solid #72727215;
          border-left: 1px solid #72727215;
        }

        .text {
          color: #ffffff;
          font-weight: 300;
          margin-right: 0.5rem;

          &.dark {
            color: #727272;
          }
        }

        .arrow {
          @include sizer(0.5rem, auto);
          opacity: 0.7;

          &.down {
            transform: rotate(90deg);
          }
        }
      }

      .submenu {
        @include position(absolute, 10rem null null 0);
        z-index: 200;

        .btnWrapper {
          @include position(absolute, 65vh auto auto 0);

          a {
            text-decoration: none;
          }
        }

        .innerItems {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding-top: 1rem;
        }

        .wrapper {
          &:hover {
            .link {
              .submenuItem {
                .arrow,
                .text {
                  transform: translateX(1rem);
                }

                &::before {
                  transform: scaleX(0) translateZ(0);
                  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                }

                &::after {
                  transform: scaleX(1) translateZ(0);
                  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
                }
              }
            }
          }
        }

        .link {
          text-decoration: none;

          &.inProgress {
            pointer-events: none !important;

            .submenuItem {
              opacity: 0.3 !important;
            }
          }

          &.dark {
            .submenuItem,
            .submenuItemInner {
              &::before,
              &::after {
                background: rgba(0, 53, 102, 1);
              }

              .text {
                color: rgba(0, 53, 102, 1);
              }
            }
          }

          .submenuItem {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            padding: 1.5rem 7rem 1.5rem 0;

            * {
              cursor: pointer;
            }

            &::before,
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 90%;
              height: 1px;
              background: #ffffff;
              opacity: 0.2;
              transform-origin: right;
              transform: scaleX(1) translateZ(0);
              transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;

              will-change: transform;
            }

            &::after {
              transform-origin: left;
              transform: scaleX(0) translateZ(0);
              transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
            }

            &:hover {
              .arrow,
              .text {
                transform: translateX(1rem);
              }

              &::before {
                transform: scaleX(0) translateZ(0);
                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
              }

              &::after {
                transform: scaleX(1) translateZ(0);
                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
              }
            }

            .text {
              font-size: 2rem;
              font-weight: 300;
              color: #ffffff;
              transition: 0.2s all ease;
            }

            .arrow {
              @include sizer(0.5rem, auto);
              margin-right: 1rem;
              transition: 0.2s all ease;
            }
          }

          .submenuItemInner {
            align-items: center;
            cursor: pointer;
            display: inline-flex;
            justify-content: flex-start;
            padding: 0.75rem 7rem 0.75rem 0;

            * {
              cursor: pointer;
            }

            .text {
              color: #ffffff;
              font-size: 1rem;
              font-weight: 300;
              transition: 0.2s all ease;
              margin-left: 2.5rem;
            }
          }
        }
      }
    }
  }

  .navMenu {
    @include position(absolute, 0 0 0 0);
    @include sizer(100%, 100vh);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    background: rgba(0, 53, 102, 0.05);
    z-index: 50;

    &.dark {
      background: #ffffff;
    }

    .closeOverlay {
      @include position(fixed, $headerHeight left auto auto);
      @include sizer(70%, 100%);
      z-index: 10;
    }

    &:after {
      content: "";
      @include position(absolute, 0 0 0 0);
      @include sizer(100%);
      backdrop-filter: blur(16px);
    }
  }
}
