@import "gerillass";
@import "../scss/variables.scss";

.footer {
  background: #f7f7f7;

  display: flex;
  flex-direction: column;

  .top {
    padding: 2.5vw 4vw 2vw 4vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include breakpoint(max, $tablet) {
      justify-content: center;
      padding: 7vw 5vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 14vw 2vw 4vw 2vw;
    }

    .links {
      display: flex;

      @include breakpoint(max, $tablet) {
        justify-content: space-between;
        flex: 1;
      }

      @include breakpoint(max, $mobile) {
        flex-direction: column-reverse;
      }

      .linkWrapper {
        cursor: pointer;
        margin-bottom: 1vw;
        padding: 0 1rem;
        transition: 0.2s all ease;

        display: flex;
        align-items: center;

        * {
          cursor: pointer;
        }

        &:hover {
          transform: translateX(1rem);
        }

        @include breakpoint(max, $tablet) {
          margin-bottom: 3.5vw;
        }

        @include breakpoint(max, $mobile) {
          margin-bottom: 5vw;
        }

        .arrow {
          padding-right: 0.75rem;
          width: 6px;

          @include breakpoint(max, $tablet) {
            width: 10px;
          }

          @include breakpoint(max, $mobile) {
            width: 5px;
          }
        }

        .link {
          color: #003566;
          font-size: 1rem;
          font-weight: 300;
          line-height: 100%;
          text-decoration: none;

          @include breakpoint(max, $tablet) {
            font-size: 3vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 1rem;
            color: #414141;
          }
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        padding-right: 5vw;

        @include breakpoint(max, $mobile) {
          padding-top: 2rem;
        }
      }

      .right {
        position: relative;

        display: flex;
        flex-direction: column;

        @include breakpoint(max, $mobile) {
          padding-bottom: 2rem;
        }

        &::before {
          content: "";
          @include position(absolute, 0 auto 0 -12%);
          @include sizer(1px, 87%);
          background: #ea0029;
          opacity: 0.4;

          @include breakpoint(max, $tablet) {
            @include position(absolute, 0 auto 0 -15%);
          }

          @include breakpoint(max, $mobile) {
            @include position(absolute, auto 0 0 0);
            @include center(horizontal);
            @include sizer(90%, 1px);
          }
        }
      }
    }
  }

  .copyright {
    padding: 0.1vw 2.5vw;

    display: flex;
    align-items: center;

    @include breakpoint(max, $mobile) {
      flex-direction: column;
      padding: 0.5rem 5vw;
    }

    .left,
    .right {
      padding: 1rem 0;

      display: flex;
      align-items: center;

      @include breakpoint(max, $mobile) {
        width: 100%;
        padding: 0.25rem;
      }
    }

    .left {
      justify-content: flex-start;
    }

    .right {
      justify-content: space-between;
    }

    small {
      color: #003566;
      font-size: 12px;
      font-weight: 300;
      line-height: 100%;
      text-decoration: none;
      padding-right: 2rem;

      @include breakpoint(max, $tablet) {
        font-size: 10px;
        padding-right: 0.75rem;
      }

      @include breakpoint(max, $tabletMini) {
        font-size: 12px;
      }

      @include breakpoint(max, $mobile) {
        font-size: 10px;
        line-height: 200%;
        color: #414141;
        padding-right: 0;
      }

      a {
        color: #003566;
        cursor: pointer;

        @include breakpoint(max, $mobile) {
          color: #414141;
        }
      }

      span {
        @include breakpoint(max, $mobile) {
          display: none;
        }
      }

      br {
        display: none;

        @include breakpoint(max, $mobile) {
          display: inline;
        }
      }
    }

    .selectWrapper {
      margin-left: auto;
    }
  }
}
