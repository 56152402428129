@import "gerillass";
@import "../scss/variables.scss";

.preloader {
  @include position(fixed, 0 0 0 0);
  @include sizer(100vw, 100vh);
  background: #000000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 99999;

  .bar {
    // @include position(absolute, 7.5vw 0 auto 0);
    @include sizer(100vw, 1px);
    background: #ffffff;
    margin-top: 0.5rem;
    transform-origin: 0 0;

    // @include breakpoint(min, $widescreen) {
    //   @include position(absolute, 6.5vw 0 auto 0);
    // }

    // @include breakpoint(max, $tablet) {
    //   @include position(absolute, 50% auto auto 0);
    //   transform: translateY(-50%);
    // }
  }

  .logo {
    //@include position(absolute, 3.1vw auto auto 2vw);
    //width: 14.9vw;

    @include breakpoint(min, $widescreen) {
      // @include position(absolute, 2.7vw auto auto 1.7vw);
      //width: 32.5vw;
    }

    @include breakpoint(max, $tablet) {
      // @include position(absolute, 2.4rem auto auto 2rem);
      width: 24vw;
    }

    @include breakpoint(max, $mobile) {
      // @include position(absolute, 1rem auto auto 1rem);
      width: 45vw;
    }
  }
}
