@import "gerillass";
@import "../scss/variables.scss";

.overflowWrapper {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: auto;
  z-index: 1000;

  @include breakpoint(max, $mobile) {
    margin-left: auto;
    margin-right: auto;
  }

  * {
    cursor: pointer;
  }

  &.sm {
    .btnWrapper {
      .btnText {
        font-size: 17px;

        @include breakpoint(max, $mobile) {
          font-size: 15px;
        }
      }
    }
  }

  .btnWrapper {
    margin: 1rem 0;
    position: relative;
    will-change: transform;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .btnText {
      color: #727272;
      display: block;
      font-size: 22px;
      font-weight: 300;
      line-height: 120%;
      text-align: left;
      padding: 0 4px;
      white-space: nowrap;

      flex: 1;
      flex-shrink: 0;

      @include breakpoint(max, $mobile) {
        font-size: 18px;
        font-style: italic;
        font-weight: 500;
      }
    }

    .iconWrapper {
      flex: 1;

      &.left {
        padding-right: 0.75rem;

        @include breakpoint(max, $mobile) {
          padding-right: 1rem;
        }
      }

      &.right {
        padding-left: 3rem;

        @include breakpoint(max, $mobile) {
          padding-left: 1rem;
        }
      }

      .arrow {
        display: block;
      }
    }
  }

  .underlineL,
  .underlineR {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    opacity: 0.3;
    transform-origin: right;
    transform: scaleX(1) translateZ(0);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
    will-change: transform;
    width: 100%;
  }

  .underlineL {
    transform-origin: left;
    transform: scaleX(0) translateZ(0);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    .underlineR {
      transform: scaleX(0) translateZ(0);
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .underlineL {
      transform: scaleX(1) translateZ(0);
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
    }
  }
}
